<template>
  <div >
    <LayoutDesktopTopbar @toggle-menu="()=>{}" />
    <div class="mt-20">       
        <slot></slot>
    </div>
    <GlobalLoading />
    <GlobalToast />
  </div>
</template>

<script setup>
import desktopTokenDatabase from "~/services/database/desktopToken";
import { usePreweddingStore } from "@/stores/prewedding";
import { useAppStateStore } from "@/stores/appState";
import { useEventStore } from "@/stores/event";
import { useUserStore } from "@/stores/user";
import eventDatabase from "~/services/database/event";
import dayjs from "dayjs"

const route = useRoute();
const router = useRouter();
const bootstrapStore = useBootstrapStore();
const authStore = useAuthStore();
const appStateStore = useAppStateStore();
const userStore = useUserStore();


const isLoading = ref(false)

onBeforeMount(async()=>{
    appStateStore.isDesktop = true
    const code = localStorage.getItem("desktop-id")
    const loginPath = "/wish/desktop/login"
      console.log(code)

    if(!code) {
      router.push(loginPath)
    }

    
    isLoading.value = true
    //Check Code For Auth And Get Data
    const eventData = await eventDatabase().getEventByPairCode(code?.toUpperCase().trim())         
    if(!eventData.pairCode){
        alert("Pair Code Not Found! ")
        router.push(loginPath)
        return;
    }   

    const user = findFirstUserEnable(eventData.user)
    
    //Get User     
    if(!user){
        alert("User Not Found! ")
        router.push(loginPath)
        return;
    }   

    const userData = await userStore.getUserById(user.userId)    
    authStore.setUserAuthData(userData)  

    //Run Bootstrap Main Desktop
    await bootstrapStore.mainDesktop()


    isLoading.value = false  
})

const findFirstUserEnable = (users) => {
  const usersIdArr = Object.keys(users);
  for (let index = 0; index < usersIdArr.length; index++) {
    const user = getUserObjData(users, index);
    if (user) {
      return user;
    }
  }
  return null;
};

const getUserObjData = (data, index = 0) => {
  const keys = Object.keys(data);
  if (keys.length > 0) {
    const key = keys[index];
    if (data[key]) {  // If the value is true
      return { userId: key, value: data[key] };
    }
  }
  return null;
};

 </script>