<template>
  <header
    class="fixed inset-x-0 top-0 z-40 flex h-16 border border-gray-900/10 bg-white shadow-sm mx-auto"
  >
    <div
      class="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8"
    >
      <div class="flex flex-1 items-center">
        <p class="text-lg font-medium">{{eventStore.getWeddingTitle}}</p> 

        <div class="ml-10 flex items-center space-x-5">
          <button 
            @click="goTo('upload')"
            class="w-28 h-16  bg-transparent" 
            :class="{'border-b-4 border-gray-700' : currentPage === 'upload'}"
          >Prewedding</button>

          <button 
            @click="goTo('sort')"
            class="w-28 h-16 bg-transparent" 
            :class="{'border-b-4 border-gray-700' : currentPage === 'sort'}"
          >Wishes</button>
        </div>
      </div>

      <div class="flex flex-1 items-center justify-end gap-x-8">
        <button
          v-if="false"
          type="button"
          class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">View notifications</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
            />
          </svg>
        </button>

        <!-- Personal Information -->
        <div class="flex justify-between">
          <a href="#" class="">
            <span class="sr-only">Your profile</span>
            <img
              class="h-10 w-10 rounded-full bg-gray-800 mr-2"
              :src="profile && profile.pictureUrl"
              alt=""
            />
          </a>
          <div class="text-sm text-gray-500">
            {{ profile && profile.displayName }}<br />
            Event ID : {{ authStore.user && authStore.user.eventId }}
            
          </div>
          
        </div>

        <!-- Logout BTN -->
        <button @click="logout">
          
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="0.8"
            stroke="currentColor"
            class="size-6 h-8 w-16"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
            />
          </svg>
        </button>
      </div>
    </div>
  </header>
</template>

<script setup>
const route = useRoute();
const router = useRouter();
const appStateStore = useAppStateStore();
const authStore = useAuthStore();
const eventStore = useEventStore();

const title = computed(() => appStateStore.menuSelected);

const profile = computed(() => authStore.user && authStore.user.line);
const currentPage = computed(() => {
  const path = route.fullPath

  switch (path) {
    case '/v2/desktop/sort':
      return 'sort'
  
    default:
      return 'upload'
  }
})

const goTo = (page) => {

  switch (page) {
    case 'upload':
      router.push('/wish/desktop/upload')
      break;

    case 'sort':
      router.push('/v2/desktop/sort')
      break;
  
    default:
      break;
  }

}

const back = () => {
  appStateStore.setMenuSelected("Photo Wish");
};

const logout = ()=>{
  const isConfirm = confirm("ต้องการออกจากระบบหรือไม่ ?")
  if(!isConfirm){
    return
  }

  localStorage.removeItem("desktop-id")
  router.push("/wish/desktop/login")

}
</script>
